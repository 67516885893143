import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
Vue.use(Router)
import { getSubscriptionStatus, getProfile } from '@/apis/services'
// import Layout from '@/layout'
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: "/p/about",
    name: "About",
    component: () => import('@/views/About.vue'),
  },
  {
    path: "/p/terms",
    name: "About",
    component: () => import('@/views/Terms.vue'),
  },
  {
    path: "/p/privacy",
    name: "About",
    component: () => import('@/views/Privacy.vue'),
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () => import('@/views/PasswordPage.vue'),
  },
  {
    path: "/reset-password",
    name: "ResetPasswordPage",
    component: () => import('@/views/ResetPasswordPage.vue'),
  },
  {
    path: "/subscription-page",
    name: "SubscriptionPage",
    component: () => import('@/views/SubscriptionPage.vue'),
  },
  {
    path: "/card-page",
    name: "CardPage",
    component: () => import('@/views/CardPage.vue'),
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: () => import('@/views/MyProfile1.vue'),
    beforeEnter : guardMyroute,
  },
  {
    path: "/confirmation-page",
    name: "ConfirmationPage",
    component: () => import('@/views/confirmation-page/index.vue'),
    beforeEnter : guardMyroute,
  },
  {
    path: "/register-pay",
    name: "registerPay",
    component: () => import('@/views/payment/index.vue'),
    beforeEnter : hasSubscription,
  },
  {
    path: "/upsell",
    name: "upsell",
    component: () => import('@/views/upsell/index.vue'),
    beforeEnter : guardUpsellRout,
  },
  {
    path: "/order-summary",
    name: "OrderSummary",
    component: () => import('@/views/order-summary/index.vue'),
    beforeEnter : guardUpsellRout,
  },


];
function isAuthenticate() {
  let isAuthenticated = false;
  if (localStorage.getItem('access_token')) {
    isAuthenticated = true;
  } else  {
    isAuthenticated= false;
  }
  return isAuthenticated
}


function guardMyroute(to, from, next) {
  let isAuthenticated = isAuthenticate();
  if (!isAuthenticated) {
    next({ path: '/' })
  } else {
    next()
  }
}
function guardUpsellRout (to, from, next) {
  let isAuthenticated = isAuthenticate();
  if (!isAuthenticated) {
    next({ path: '/' })
  } else {
    getSubscriptionStatus().then( res => {
      if (res) {
        let subscription = res.data.data
        if ( subscription?.plan?.interval == "YEARLY") {
          next()
        } else {
          next({ path: '/' })
        }
      }
    }).catch ( e => {
      next({ path: '/' })
    })
  }
}

function hasSubscription (to, from, next) {
  let isAuthenticated = isAuthenticate();
  if (!isAuthenticated) {
    next()
  } else {
    let user = null
    if (localStorage.getItem('user') && localStorage.getItem('user')!=='undefined') {
      user = JSON.parse(localStorage.getItem('user'));
    }
    if (user) {
      getProfile().then(res => {
        let subscription = res.data.data.subscription;
        if (subscription) {
            let is_active = subscription.is_active
            if ( is_active && subscription.status == 1 && to.query.upgrade != 'true') {
              console.log('/my-profile')
              next({ path: '/my-profile' })
            } else {
              next()
            }
        } else {
          next()
        }
      }).catch ( e => {
        next()
      })
    } else {
      next()
    }

  }
}


const createRouter = () => new Router({
  mode: 'history', // require service support
  // scrollBehavior: () => ({ y: 0 }),
  routes
})

const router = createRouter()
router.beforeEach((to, from, next) => {
  let promise = Promise.resolve();
  if (!store.state.homeInfo)
    promise = store.dispatch('getHomeInfo');
  promise.then(() => {
    if (hasQueryParams(to)) {
      if (hasQuery(to, 'login_secret_token')) {
        return handleSecretToken(to, next)
      }
    }
    if (!hasQueryParams(to) && hasQueryParams(from)) {
      next({name: to.name, query: from.query});
    } else {
      next()
    }
  })
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}
function hasQuery(route,key) {
  return !!route.query.hasOwnProperty(key)
}

async function handleSecretToken(route, next) {
  await saveAccessToken(route.query["login_secret_token"])
  await getUser()
  delete route.query["login_secret_token"]
  next({ name: route.name, query: route.query });
}

function saveAccessToken(access_token) {
  localStorage.setItem("access_token", JSON.stringify(access_token));
}

function getUser() {
  return getProfile().then(res => {
    if (res.data.success) {
      store.commit("SET_USER", res.data.data.user);
    }
  })
}
export default router
