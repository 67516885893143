 <template>
 <div 
    class="popup-body">
    <template>
      <h1>إعادة تعيين كلمة المرور</h1>
      <form>
        <!-- Email -->
        <div class="input-field mail-field">
          <label class="input-label">
            البريد الالكتروني
          </label>
          <div class="input-control">
            <input 
            class="form-control1"
            type="text" 
            v-model="user.email" 
            :class="{ 'is-invalid': $v.user.email.$error }"
            placeholder="البريد الالكتروني"/>
            <img :src='"assets/images/icons/mail-icon.png"' class="input-icon"/>
          </div>
        </div>
        <!-- Submit -->
        <div class="d-flex justify-content-center">
          <button type='button' class="login"  @click="submit()">
            إعادة تعيين كلمة المرور
          </button>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import {required, email} from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      user: {
        email: '',
      },
    }
  },
    methods: {
    validateForm() {
      this.$v.$touch()
      return !this.$v.$invalid 
    },
    submit() {
      let validation = this.validateForm()
      if (validation) {
        this.$emit('resetPassword',this.user)
      } else {
        return false
      }
    },
  
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
    },
  },
}
</script>