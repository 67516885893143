import axios from 'axios';


export default function setup() {
    axios.interceptors.request.use(function(config) {
        const token = JSON.parse(localStorage.getItem('access_token')); 
        if( token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, function(err) {
        return Promise.reject(err);
    });
    axios.interceptors.response.use(
        (response) => {
           // all 2xx/3xx responses will end here
           if (!response.data.authorization) {
                // localStorage.removeItem('access_token')
                // localStorage.removeItem('user')
                //window.location.href = '/'
           }
           return response;
        },
        (error) => {
           // all 4xx/5xx responses will end here
           
           return Promise.reject(error);
        }
      );
}