import axios from 'axios'

let baseURL = process.env.VUE_APP_REMOTE_URL


export function login(type,data) {
    return axios.post(baseURL+'api/'+type,data)
}

 
export function logout() {
    return axios.post(baseURL+'api/logout')
}

export function socialiteLogin(data) {
    return axios.post(baseURL+'api/auth/socialite',data)
}

export function refresh() {
    return axios.post(baseURL+'api/refresh')
}
export function resetPassword(data) {
    return axios.post(baseURL + 'api/reset-password/create', data)
}


