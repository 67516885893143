import axios from 'axios'

let baseURL = process.env.VUE_APP_REMOTE_URL


export function getData() {
    return axios.get(baseURL + 'landing-page');
}

export function verifyCoupon(code, planId) {
    return axios.get(baseURL + 'api/payment/check-coupon?', {
        params: {
            code: code,
            plan_id: planId
        }
    })
}

export function verifyCard(card) {
    return axios.post(baseURL + 'api/payment/verify-card', card)
}

export function getSubscriptionStatus() {
    return axios.get(baseURL + 'api/payment/subscription-status')
}

export function getProfile() {
    return axios.get(baseURL + 'api/profile')
}

export function editUser(data) {
    return axios.post(baseURL + 'api/user/edit', data)
}

export function cancelSubscription() {
    return axios.put(baseURL + 'api/payment/cancel-subscription')
}

export function subscribe(data) {
    return axios.post(baseURL + 'api/payment/subscribe', data)
}

export function deleteUser() {
    return axios.delete(baseURL + 'api/user')
}

export function upgradeSubscription(data) {
    return axios.post(baseURL + 'api/payment/upgrade-subscription', data)
}

export function upsell() {
    return axios.post(baseURL + 'api/payment/upsell')
}

export function getUpsellSummary() {
    return axios.get(baseURL + 'api/payment/upsell-summary')
}

export function createStripeCheckoutSession(data) {
    return axios.post(baseURL + 'api/payment/create-checkout-session', data)
}
