<template>
  <div class="d-flex justify-content-center">
    <button type="button" class="login-btn" v-google-signin-button="clientId">
      <img :src='"assets/images/icons/google.png"' class="btn-icon"/> 
      <template v-if="loginForm">
        <span>Google</span>
        سجل دخول ب 
      </template>
      <template v-else>
        <span>Google</span>
        أكمل باستخدام  
      </template>
    </button>

    <!-- <button type="button" class="login-btn dark-btn">
      <img :src='"assets/images/icons/apple.png"' class="btn-icon"/> 
      <template v-if="loginForm">
        <span>Apple</span>
        سجل دخول ب 
      </template>
      <template v-else>
        <span>Apple</span>
        أكمل باستخدام  
      </template>
    </button> -->
  </div>
</template>
<script>
import {GOOGLE_ID} from '@/config/index.js'
import {initFbsdk} from '@/config/facebook_oAuth.js'
import { socialiteLogin } from '@/apis/auth'
export default {
  props: ['loginForm'],
  data() {
    return {      
      clientId: GOOGLE_ID,
    }
  },
  methods: {
     OnGoogleAuthSuccess(idToken) {
      socialiteLogin({provider: 'google', token: idToken}).then(res => {
          this.socialLogin(res)
      })
      // Receive the idToken and make your magic with the backend
    },
    OnGoogleAuthFail(error) {
        console.log(error)
    },
    socialLogin(event) {
      this.showMessage(event)
      if (event.data.success) {
        this.$gtm.trackEvent({
          event: "login",
          action: "Login",
          label: "Login Test",
          catgory: "Authentication",
          method: event.data.data.user.login_type
        });
        this.setAuthData(event)
        this.$emit('closePopup')
      }
    },
    setAuthData(result) {
      this.$store.commit("SET_USER", result.data.data.user);
      localStorage.setItem(
        "access_token",
        JSON.stringify(result.data.data.access_token)
      );
    },
    showMessage(result) {
      this.$store.commit("SET_MESSAGE", {
        message: result.data.message,
        errors: result.data.data,
        status: result.data.success
      });
    },
  },
  mounted() {
    initFbsdk()
  },
}
</script>