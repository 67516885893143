<template>
<div>
    <transition name="fade" appear>
        <div class="modal-container" v-if="flashMessage" @click.self="close">
        </div>
    </transition>
    <transition name="bounce" appear>
        <div class="modal-content1"  v-if="flashMessage">
            <div class="modal-icon">
                <div v-if="!flashMessage.status">
                    <img src="/assets/images/icons/modal-error.png"/>
                </div>
                <div v-else>
                    <img height='45px' src="/assets/images/icons/check.svg"/>
                </div>
            </div>
            <div class="modal-message font-bold">
                {{flashMessage.message}}
                <template v-if="!flashMessage.status">
                    <div v-for="(error,key) in flashMessage.errors" :key="key" >
                        {{error[0]}}
                    </div>
                </template>
            </div>
            <button class="btn-main d-block" v-if="!flashMessage.status" @click.self="close"> المحاولة مرة أخرى</button>
        </div>
    </transition>
</div>
</template>
<script>
export default {
    computed: {
		flashMessage () {
			return this.$store.state.flashMessage
        }
    },
    watch: {
        flashMessage() {
            if (this.flashMessage?.status) {
                setTimeout(() => {
                    this.close()
                }, 3000); 
            }
        }
    },
    methods: {
        close() {
            this.$store.commit('CLOSE_MODAL')
        }
    },
    mounted() {
        
    }
}
</script>
<style lang="scss" scoped>

    .modal-container {
        direction: rtl;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100000;
        padding: 10px;
        box-sizing: border-box;
        background-color:rgba(30, 45, 52, 0.7);
        padding-bottom: 42px;
        min-height: 600px;
        overflow: hidden;
        &.fade-enter,
        &.fade-leave-to {
            opacity: 0;
        }
        &.fade-enter-active,
        &.fade-leave-active {
            transition: opacity 0.3s;
        }
    }
    .modal-content1 {
        position: absolute;
        position: fixed;
        top: 0;
        right: 0;
        
        left: 0;
        margin: auto;
        text-align: center;
        width: 536px;
        margin: 120px auto;
        background: #F8F8F8;
        padding: 59px 22px 51px;
        box-sizing: border-box;
        z-index: 1000011;
        // animation: bounce-in .5s ease-in-out;
        @media (max-width: 900px) {
            width: 348px;
            background-color: #fff;
            border-radius: 10px;
        }
        .modal-icon {
            margin-bottom: 27px;
            text-align: center;
            @media (max-width: 900px) {
                margin-bottom: 22px;
            }
            div {
                background: rgba(248, 151, 21, 0.1);
                height: 96px;
                width: 96px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50% ;
                margin: auto;
            }
        }
    }
    // &.dark {
    //     .modal-content1 {
    //     background: #313F46;
    //     color: #fff;
    //     }
    // }
    
    .modal-message {
        font-size: 18px;
        line-height: 34px;
        color: #000;
        @media (max-width: 900px) {
            color: #575656;
        }
        div {
            font-size: 15px;
        }
    }
    @media only screen and (max-width: 900px) {
        .modal-content {
            padding: 33px 19px !important;
        }
    }
    button {
        width: 335px;
        max-width: 335px;
        margin: auto;
        margin-bottom: 20px;
        background: linear-gradient(90deg, #FF7700 0%, #F49624 100%);
        height: 50px;
        border-radius: 8px;
        margin-top: 33px;
        @media (max-width: 900px) {
          width: 304px;
          max-width: 335px;
        }
        &.btn-plain {
            background: #fff;
            border: 1px solid #FF7700;
            color: #FF7700;
        }
    }
    .bounce-enter-active {
    animation: bounce-in .5s;
    }
    .bounce-leave-active {
    animation: bounce-in .5s reverse;
    }
    @keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
    }
</style>