<template>
  <div 
    class="popup-body">
    <template>
      <h1>سجل الدخول لمختصر</h1>
      <form>
        <!-- Email -->
        <div class="input-field mail-field">
          <label class="input-label">
            البريد الالكتروني
          </label>
          <div class="input-control">
            <input 
            class="form-control1"
            type="text" 
            v-model="user.email" 
            :class="{ 'is-invalid': $v.user.email.$error }"
            placeholder="البريد الالكتروني"/>
            <img :src='"assets/images/icons/mail-icon.png"' class="input-icon"/>
          </div>
        </div>
        <!-- Password -->
        <div class="input-field">
          <label class="input-label">كلمة المرور</label>
          <div class="input-control">
            <input
            
            :type="type"
            v-model="user.password" 
            class="register-form-control form-control1"
            :class="{ 'is-invalid': $v.user.password.$error }" 
            placeholder="كلمة المرور"/>
            <img :src='"assets/images/icons/lock.png"' class="input-icon"/>
            <img 
              :src='"assets/images/icons/eye-icon.png"' 
              class="show-icon"
              @click="showPassword"/> 
          </div>
          <span class="forget-password" @click="$emit('showResetForm')">نسيت كلمة المرور ؟</span>
        </div>
      
        <!-- Submit -->
        <div class="d-flex justify-content-center">
          <button type='button' class="login"  @click="handleLogin()">
           تسجيل دخول
          </button>
        </div>
      </form>
        <!-- Divider -->
        <p class="title-line"> او انشاء حساب ب</p>
    </template>
  </div>
</template>
<script>
import {required, minLength, email} from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      type: 'password',
      user: {
        email: '',
        password: ''
      },
    }
  },
  methods: {
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text'
      } else {
        this.type = 'password'
      }
    },
    validateForm() {
      this.$v.$touch()
      return !this.$v.$invalid 
    },
    handleLogin() {
      let validation = this.validateForm()
      if (validation) {
        this.$emit('login',this.user)
      } else {
        return false
      }
    },
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    },
  },
}
</script>