<template>
    <header :class="{'noUser': !hasUser && $route.name == 'registerPay'}">
			<div :class="['header', 'd-flex',' justify-content-between', 'align-items-center' ] ">
				<div class="" v-if="!hasUser && $route.name !== 'registerPay'">
						<button class="btn-main" id='login'  @click="login()"> تسجيل الدخول</button>
						<button class="btn-main" id='join' @click="handleBtnClick()">انضم الآن</button>
				</div>
				<div class="nav-item text-white cursor-pointer" v-if="$route.name == 'upsell'||$route.name == 'OrderSummary'" @click="goBack">
					<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12 17.7859L5 11.4787L12 5.17139" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</div>
					<div class="text-white" v-else-if="hasUser">
						<div class="nav-item dropdown">
							<a class="nav-link d-flex justify-content-between align-items-center" data-toggle="dropdown">
								<div class="pl-2 user-name">
									<div>{{user.name}}
										<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M0.92589 2.61339C0.952015 2.5872 0.983051 2.56642 1.01722 2.55224C1.05139 2.53806 1.08802 2.53076 1.12501 2.53076C1.16201 2.53076 1.19864 2.53806 1.23281 2.55224C1.26698 2.56642 1.29801 2.5872 1.32414 2.61339L4.50001 5.78983L7.67589 2.61339C7.70204 2.58724 7.73308 2.5665 7.76725 2.55234C7.80141 2.53819 7.83803 2.53091 7.87501 2.53091C7.91199 2.53091 7.94861 2.53819 7.98278 2.55234C8.01695 2.5665 8.04799 2.58724 8.07414 2.61339C8.10029 2.63954 8.12103 2.67058 8.13518 2.70475C8.14933 2.73891 8.15662 2.77553 8.15662 2.81251C8.15662 2.8495 8.14933 2.88611 8.13518 2.92028C8.12103 2.95445 8.10029 2.98549 8.07414 3.01164L4.69914 6.38664C4.67301 6.41283 4.64198 6.43361 4.60781 6.44779C4.57364 6.46197 4.53701 6.46927 4.50001 6.46927C4.46302 6.46927 4.42639 6.46197 4.39222 6.44779C4.35805 6.43361 4.32701 6.41283 4.30089 6.38664L0.92589 3.01164C0.899698 2.98551 0.878917 2.95448 0.864739 2.92031C0.85056 2.88614 0.843262 2.84951 0.843262 2.81251C0.843262 2.77552 0.85056 2.73889 0.864739 2.70472C0.878917 2.67055 0.899698 2.63952 0.92589 2.61339Z" fill="white" stroke="white"/>
										</svg>

									</div>
								</div>
								<div class="header-avatar"></div>
							</a>
							<div class="dropdown-menu dropdown-menu-left">
								<router-link class="dropdown-item" tag="a" to="/my-profile">
								<img src="/assets/images/icons/user.png"/>
									ملفي الشخصي</router-link> 
								<a class="dropdown-item" @click="logout">
									<img src="/assets/images/icons/logout.png"/>
									تسجيل خروج
								</a>
							</div>
						</div>
					</div>

					<div 
						v-if="!hasUser && $route.name == 'registerPay'"
						class="login-header w-100">
						<div class="header-desc text-white text-right">
							<p>مرحبا بك في مختصر</p>
							<p>
								<span class="text-orange" @click="showModal=true">تسجيل الدخول</span> 
								لإكمال عملية الاشتراك في مختصر قم 
								
							</p>
						</div> 
						<div class="logo-section" @click="$router.push({path:'/'})">
							<span class="text-white">مخُتصر</span>
							<img src='/assets/images/small-logo.png' />
						</div>
					</div>
					<div class="brand-logo ml-auto d-flex" v-else  @click="$router.push({path:'/'})">
						<span class="app-name">مخُتصر</span>
						<img src='/assets/images/logo.png' class="d-none d-md-inline-block"/>
						<img src='/assets/images/small-logo.png' class="d-inline-block d-md-none"/>
					</div>
			</div>
			<AuthPopup 
					v-if="showModal"
					@closePopup="closePopup"/>
    </header>
</template>
<script>
import { logout } from '@/apis/auth'
import AuthPopup from '../components/auth-popup/index.vue'
export default {
  components: {
		AuthPopup
	},
	data() {
		return {
			showModal: false,
			mode: 'login',
			hasUser: null,
		}
	}, 
	computed: {
		user () {
			return this.$store.state.user
		}
	},
	watch:{
    showLoginModal(val){
			this.showModal = val
			if (val) {
				document.body.classList.add('modal-open');
			} else {
				document.body.classList.remove('modal-open');
			}
		},
		user(val) {
			this.hasUser = val !==null
		},
		modePage(val) {
			console.log('val',val)
			this.mode = val
		}
	},
	methods: {
		closePopup() {
      this.showModal = false
    },
		goBack() {
			this.$router.go(-1)
		},
		hideModalHandler() {
			this.showModal = false;
			this.$store.commit('SHOW_LOGIN_MODAL',{showLoginModal:false,modePage:""})
		},
		openLoginForm(mode) {
			this.$store.commit('SHOW_LOGIN_MODAL',{showLoginModal:true,modePage:mode})
		},
		login() {
			this.showModal = true;
		},
		handleBtnClick() {
			$('html, body').animate({
				scrollTop: $('#pricing-plans').offset().top - $('.header').outerHeight()
			}, 1000);
		},
		logout() {
			logout().then( res => {
				localStorage.removeItem('access_token')
				localStorage.removeItem('user')
				this.$store.commit('SET_USER', null)
				this.$store.commit('SET_MESSAGE', {
					message: res.data.message,
					status: res.data.success
				})
				//this.showMessage( res.data.message,res.data.success)	
				if (this.$route.name == 'MyProfile') {
					this.$router.push({ path: '/' })
				}
			})
		},
		
	},
	mounted() {

		if (!this.user) {
			if (localStorage.getItem('user') && localStorage.getItem('user')!=='undefined') {
				const user = JSON.parse(localStorage.getItem('user'));
				this.$store.commit('SET_USER', user)	
			}
		}
		$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.header').addClass('fixed');
		} else {
			$('.header').removeClass('fixed');
		}
		});
	}
}
</script>
<style scoped>
.login-header {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 27px;
	padding: 10px 0 11px;
}
.login-header .logo-section {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 7px;
}
.login-header .logo-section span {
	display: inline-block;
}
.header-desc {
	font-size: 18px;
}
.header-desc p {
	margin-bottom: 0;
}
.header-desc p:first-child {
	margin-bottom: 5px;
	margin-right: 10px;
}
.header-desc span {
	display: inline-block;
	cursor: pointer;
}

@media only screen and (max-width: 600px) {
	.login-header {
		flex-direction: column-reverse;
		gap: 12px;
	}
	.login-header .logo-section {
		margin-left: auto;
	}
	.header-desc {
		font-size: 14px;
	}
	.header-desc p:first-child {
		margin-bottom: 5px;
		margin-right: 4px;
	}
}

.text-white {
	color: #fff;
}
.dropdown-menu {
	direction: rtl;
	background: #FFFFFF;
	border: 1px solid #C6C2DE;
	box-sizing: border-box;
	box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.08);
	border-radius: 6px;
	padding: 24px 11px;
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
	top: 18px !important;
	left: -18px !important;
	width: 202px;
}

.dropdown-menu:before {
	content: "";
    position: absolute;
    top: -7px;
    left: 50%;
	margin-left: -14px;
    display: block;
    background: inherit;
    width: 28px;
    height: 28px;
    transform-origin: center;
    border: 1px solid;
    border-color: inherit;
    z-index: -1;
	clip: rect(0,19px,20px,0);
	-ms-transform: rotate(33deg) skewY(25deg);
  	transform: rotate(33deg) skewY(25deg);
} 

a.dropdown-item {
	padding: 2px 19px;
	font-size: 14px;
	line-height: 27px;
	text-align: right;
	color: #25213B;
	font-family: "NotoKufiArabic-Regular";
}
.dropdown-menu img {
	margin-left: 16px;
}
a.dropdown-item:hover {
	background: rgba(255, 120, 2, 0.07);
	border-radius: 4px;
}
.header #login {
	display: block;
}
.header #join {
	display: none;
}
.header.fixed #login {
	display: none;
}
.header.fixed #join {
	display: block;
}
</style>