<template>
  <div class="popup" :class="{ 'register-mode': !loginForm ,'forget-mode': showResetForm }">
    <div class="modal-backdrop" @click.self="$emit('closePopup')"></div>
    <div class="popup-dialog">
      <div class="popup-content">
        <!-- Start Header -->
        <div class="popup-header">
          <img class="logo" width='36' height="32" :src='"assets/images/small-logo.png"'/>
          <p>
            مرحبا بك في 
            <span>مُختصر</span>
          </p>
        </div>
        <!-- End Header -->
        <div class="popup-dialog-body">
          <forget-password
            v-if="showResetForm"
            @resetPassword='resetPassword'/>

          <template v-else>
            <login-form 
              v-if="loginForm"
              @login='submitLogin'
              @showResetForm='showResetForm=true'/>
            <register-form 
              v-if="!loginForm"
            @register='submitLogin'/>
            <social-sign 
            :loginForm='loginForm'
            @closePopup="$emit('closePopup')"/>
          </template>
        </div>

       <!-- Start Footer -->
       <template v-if="showResetForm">
        <div class="popup-footer" @click="showResetForm=false">
          <p>
          رجوع
          </p>
        </div>
       </template>
       <template v-else>
        <div class="popup-footer" @click="loginForm=!loginForm">
          <p v-if="loginForm">
          مستخدم جديد؟ إنشاء حساب
          </p>
          <p v-else>
            أمتلك حساب ؟ سجل دخول
          </p>
        </div>
       </template>
        <!-- End Footer -->
    </div>
    </div>
  </div>
</template>
<script>
import { login, resetPassword } from '@/apis/auth'

import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import SocialSign from './SocialSign'
import ForgetPassword from './ForgetPassword'

export default {
  components: {
    LoginForm,
    RegisterForm,
    SocialSign,
    ForgetPassword
  },
  data() {
    return {
      loginForm:true,
      type: 'password',
      showResetForm: false,
      scrollY: null
    }
  },
  computed: {
    formType() {
      return this.loginForm?'login':'register'
    }
  },
  methods: {
    submitLogin(user) {
      this.login(user)
    },
    async login(user) {
      try {
      const result = await login(this.formType,user)
        if (result) {
          this.showMessage(result)
          if (result.data.success) {
            this.$gtm.trackEvent({
              event: "login",
              action: "Login",
              label: "Login Test",
              category: "Authentication",
              method: result.data.data.user.login_type
            });
            this.setAuthData(result)
            this.$emit('closePopup')
          }
        }
      } catch {
        
      }

    }, 
    async resetPassword(user) {
      try {
      const result = await resetPassword(user)
        if (result) {
          this.showMessage(result)
          if (result.data.success) {     
            this.showResetForm = false
          }
        }
      } catch {
        
      }
    },
    setAuthData(result) {
      this.$store.commit("SET_USER", result.data.data.user);
      localStorage.setItem(
        "access_token",
        JSON.stringify(result.data.data.access_token)
      );
    },
    showMessage(result) {
      this.$store.commit("SET_MESSAGE", {
        message: result.data.message,
        errors: result.data.data,
        status: result.data.success
      });
    },

  }, 
  mounted() {
    if (this.$route.name == 'registerPay') {
      this.loginForm = false
    } else {
      this.loginForm = true
    }
  },
  beforeCreate() {
    const body = document.body;
    this.scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    body.style.height = '100vh';
    body.style.overflowY = 'hidden';
  },
  beforeDestroy() {
    const body = document.body;
    body.style.height = '';
    body.style.overflowY = '';
    this.scrollY = body.style.top;
  }
}
</script>