<template>
	<div id="app">
		<the-header/>
			<router-view />
		<the-footer/>
		<message-box/> 
	</div>
</template>

<script>
import TheHeader from './layout/TheHeader.vue'
import TheFooter from './layout/TheFooter.vue'
import MessageBox from '@/components/ui/MessageBox'

export default {
	name: 'App',
	components: {
		TheHeader,
		TheFooter,
		MessageBox
	},
	computed: {
		flashMessage () {
			return this.$store.state.flashMessage
		},
	},
	methods: {
		
	},

	mounted() {
		
		
	},
	destroyed() {
		localStorage.removeItem('couponCode')
	}
}
</script>

<style>

</style>
