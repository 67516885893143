<template>
  <div 
    class="popup-body">
    <template>
      <h1>انشاء حساب في مختصر</h1>
      <form>
        <!-- Name -->
        <div class="input-field">
          <label class="input-label">
            الاسم 
          </label>
          <div class="input-control">
            <input 
            type="text" 
            class="form-control1"
            v-model="user.name" 
            :class="{ 'is-invalid': $v.user.name.$error }"
            placeholder="الاسم"/>
            
            <img :src='"assets/images/icons/user-icon.png"' class="input-icon"/>
          </div>
        </div>
        <!-- Phone -->
        <div class="input-field">
          <label class="input-label">
            رقم الموبايل
          </label>
          <div class="input-control phone-required"
            :class="{ 'is-invalid': invalidPhone }">
            <vue-phone-number-input 
            v-model="vuePhone.phone" 
            v-bind="vuePhone.props" 
            @update="results = $event"
            error-color="#ff0000"
            :required='true'
            :error='results?(results.phoneNumber&&!results.isValid):false'
            >
            </vue-phone-number-input>

          </div>
        </div>
        <!-- Email -->
        <div class="input-field mail-field">
          <label class="input-label">البريد الالكتروني</label>
          <div class="input-control">
            <input 
            type="text" 
            class="form-control1"
            v-model="user.email" 
            :class="{ 'is-invalid': $v.user.email.$error }"
             placeholder="البريد الالكتروني"/>
            <img :src='"assets/images/icons/mail-icon.png"' class="input-icon"/>
          </div>
        </div>
        <!-- Password -->
        <div class="input-field">
          <label class="input-label">كلمة المرور</label>
          <div class="input-control">
            <input
            :type="type"
            
            v-model="user.password" 
            class="register-form-control form-control1"
            :class="{ 'is-invalid': $v.user.password.$error }" 
            placeholder="كلمة السر"/>
            <img :src='"assets/images/icons/lock.png"' class="input-icon"/>
            <img 
              :src='"assets/images/icons/eye-icon.png"' 
              class="show-icon"
              @click="showPassword"/> 
          </div>
        </div>

        <!-- Submit -->
        <div class="d-flex justify-content-center">
          <button type='button' class="login"  @click="handleRegister()">
            تسجيل 
          </button>
        </div>
        <!-- Signup Hint -->
        <p class="font-bold text-small">
          عند انشائك حسابا في مختصر فأنت توافق على
          <a class="text-orange cursor-pointer" 
          @click="$router.push('/p/privacy')"> سياسة الخصوصية  </a>  
          الخاصة بنا و 
          <a class="text-orange cursor-pointer" 
          @click="$router.push('/p/terms')">  شروط الاستخدام  </a>  
        </p>
      </form>

      <!-- Divider -->
      <p class="title-line">او سجل دخول ب</p>

    </template>
  </div>
</template>
<script>
import {required, minLength, email, requiredIf} from 'vuelidate/lib/validators'

import VueTelInput from 'vue-tel-input';


export default {
  
  data() {
    return {
      type: 'password',
      user: {
        name: '',
        email: '',
        password: '',
        phone_number: ''
      },
      results: null,
      invalidPhone: false,
      vuePhone: {
        phone: "",
        props: {
          mode: 'international',
          clearable: true,
          fetchCountry: true,
          preferredCountries: ["US", "GB"],
          noExample: false,
          translations: {
            countrySelectorLabel: "",
            countrySelectorError: "Error",
            phoneNumberLabel: "رقم الموبايل",
            example: "Example:",
          },
          
        }
      }
    }
  },
  watch: {
    results() {
      this.invalidPhone = !this.results.isValid
    } 
  },
  methods: {
   
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text'
      } else {
        this.type = 'password'
      }
    },
    validateForm() {
      this.$v.$touch()
      return !this.$v.$invalid 
    },
    handleRegister() {
      this.invalidPhone = !this.results.isValid
      let validation = this.validateForm()
      if (validation) {
        if (this.results.isValid) {
          this.user.phone_number = this.results.e164
          this.user.password_confirmation = this.user.password
          this.$emit('register',this.user)
        } 
      } else {
        return false
      }
    },
  },
  validations: {
    user: {
      name: {
        required,
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      },
     
    },
  },
}
</script>