import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

let baseURL = process.env.VUE_APP_REMOTE_URL;


Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    showLoginModal: false,
    modePage: null,
    user: false,
    selectedPlan: null,
    homeInfo: null,
    flashMessage: null,
    messageStatus: null,
    couponCode: '',
    coupon: null,
    payment_method: null
  },

  getters: {
    showLoginModal : state => state.showLoginModal,
    modePage : state => state.modePage,
    user : state => state.user,
    selectedPlan : state => state.selectedPlan,
    homeInfo : state => state.homeInfo,
    flashMessage:  state => state.flashMessage,
    couponCode: state => state.couponCode,
    coupon: state => state.coupon,
    messageStatus: state => state.messageStatus,
    payment_method: state => state.payment_method,
  },
  actions: {
    getHomeInfo({commit}, paylaod){
      return axios.get(baseURL + 'landing-page', {params: paylaod}).then((res=>{
        const homeInfo = res.data.data;
        commit('UPDATE_HOME_INFO', homeInfo);
        return res;
      }));
    }
  },
  mutations: {
    SHOW_LOGIN_MODAL (state, data) {
      state.modePage = data.modePage;
      state.showLoginModal = data.showLoginModal;
    },
    SET_USER (state, user) {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    SET_SELECTED_PLAN (state, plan) {
      if(plan) {
        localStorage.setItem("selectedPlan", JSON.stringify(plan));
        state.selectedPlan = plan;
      }
    },
    SET_MESSAGE(state, data) {
      state.flashMessage = {
        message: data.message,
        errors: data.errors,
        status: data.status,
      }
    },
    CLOSE_MODAL(state) {
      state.flashMessage = null
    },
    UPDATE_COUPON_CODE(state, couponCode) {
      localStorage.setItem("couponCode", JSON.stringify(couponCode));
      state.couponCode = couponCode;
    },
    UPDATE_COUPON(state, coupon) {
      localStorage.setItem("coupon", JSON.stringify(coupon));
      state.coupon = coupon;
    },
    UPDATE_HOME_INFO(state, homeInfo) {
      localStorage.setItem("homeInfo", JSON.stringify(homeInfo));
      state.homeInfo = homeInfo;
    },
    UPDATE_PAYMENT_METHOD(state, payment_method) {
      state.payment_method = payment_method;
    },
    
  },
})

export default store



